(function ($) {
    "use strict";

    var self;

    ori.addModule("virtualScrollBar", {
        defaults: {
            moduleName: "ORI Virtual Scrollbar", // module name
            eshopParentElemnt: ".iframe-scrollbar-wrapper", // e-shop wrapper
            eshopContentElement: "iframe", // e-shop content element, can set to any element inside ehop wrapper parent
        },

        cssClasses: {
            applied: "has-virtual-scrollbar",
            vertical: "vertical",
            horizontal: "horizontal",
        },

        themes: {
            small: "default-small",
            big: "default-big",
        },

        themeOptions: {
            "default-small": {
                cursorwidth: "1px",
            },
            "default-big": {
                cursorwidth: "4px",
            },
        },

        options: {
            autohidemode: false,
            bouncescroll: false,
            // scrollbar visual
            cursorcolor: "var(--grey-300)",
            cursorborder: "1px solid var(--grey-300)",
            background: "transparent",
            // ORI custom settings
            inBoxFloatHorizontal: false, // horizontal scrollbar vertical floating inside parent
            inBoxFloatHorizontalOffset: 0, // horizontal scrollbar vertical floating bottom offset inside parent
            animateFloatHorizontal: false, // horizontal scrollbar vertical floating animation
            animationSpeedFloatHorizontal: 15, // horizontal scrollbar vertical floating animation speed
            scrollBarTheme: "default-small",
        },

        /**
         * Initialization
         */
        init: function () {
            self.info("Module is loaded successfully.");
        },

        addVirtualScrollBar: function (parent, content, options) {
            var $parent = $(parent);
            if ($parent.length) {
                var o = $.extend(self.options, typeof options !== "undefined" ? options : {});

                if (self.themeOptions[o.scrollBarTheme]) {
                    $.extend(o, self.themeOptions[o.scrollBarTheme]);
                }

                $parent.niceScroll(content, o);

                var scrollbars = $parent.getNiceScroll();
            }
        },

        // ORI floating horizontal scrollbar
        floatHorizontalScrollBar: function (scrollbars) {
            scrollbars.each(function (i, scrollbar) {
                self.setScrollbarPosition(scrollbar);

                // ORI custom onscroll, onscreenresize
                scrollbar.bind(
                    window,
                    "scroll",
                    self.setScrollbarPositionHandler.bind(self, scrollbar)
                );

                scrollbar.bind(
                    window,
                    "resize",
                    self.setScrollbarPositionHandler.bind(self, scrollbar)
                );
                scrollbar.bind(
                    window,
                    "orientationchange",
                    self.setScrollbarPositionHandler.bind(self, scrollbar)
                );
            });
        },

        setScrollbarPosition: function (scrollbar) {
            var $scrollBarHorizontal = scrollbar.railh,
                $parent = scrollbar.me,
                insidePosition,
                bottomPos = 0,
                $win = $(window);

            if ($parent.length && $scrollBarHorizontal.length) {
                var scrollTop = $win.scrollTop(),
                    scrollBarHeight = $scrollBarHorizontal.innerHeight(),
                    parentHeight = $parent.innerHeight(),
                    parentTop = $parent.offset().top,
                    parentBottom = parentTop + parentHeight,
                    windowHeight = $win.height() + scrollTop;

                insidePosition = "inside";
                if (windowHeight > parentBottom + scrollBarHeight) {
                    insidePosition = "outside-bottom";
                } else if (
                    scrollTop > parentTop + scrollBarHeight &&
                    windowHeight <= parentBottom + scrollBarHeight
                ) {
                    insidePosition = "outside-middle";
                } else if (
                    windowHeight - parentHeight - scrollBarHeight <
                    parentTop - scrollBarHeight
                ) {
                    insidePosition = "outside-top";
                }

                switch (insidePosition) {
                    case "inside":
                        bottomPos = 0;
                        break;
                    case "outside-top":
                        $parent.css({
                            paddingTop: scrollBarHeight + "px",
                        });
                        bottomPos = parentHeight - scrollBarHeight;
                        break;
                    case "outside-middle":
                        $parent.css({
                            paddingTop: scrollBarHeight + "px",
                        });
                        bottomPos = parentBottom - windowHeight + scrollBarHeight;
                        break;
                    case "outside-bottom":
                        $parent.css({
                            paddingTop: scrollBarHeight + "px",
                        });
                        bottomPos = 0;
                        break;
                }

                bottomPos -= self.options.inBoxFloatHorizontalOffset;

                if (self.options.animateFloatHorizontal) {
                    $scrollBarHorizontal.stop().animate(
                        {
                            bottom: bottomPos,
                        },
                        self.options.animationSpeedFloatHorizontal
                    );
                } else {
                    setTimeout(function () {
                        $scrollBarHorizontal.css({
                            bottom: bottomPos,
                        });
                    }, 80);
                }
            }
        },

        setScrollbarPositionHandler: function (scrollbar, event) {
            self.setScrollbarPosition(scrollbar);
        },

        // public methods
        apply: function (content, options) {
            var $content = $(content);
            if ($content.length) {
                var $parent = $content.parent();
                self.addVirtualScrollBar($parent, $content, options);
            }
        },

        hasScrollBar: function ($elem) {
            var scrollbarData = $elem.getNiceScroll();
            return scrollbarData && scrollbarData.length > 0;
        },

        refresh: function ($elem) {
            // jQuerify if DOM Element
            if ($elem instanceof Element) {
                $elem = $($elem);
            }

            var scrollbarData = $elem.getNiceScroll();
            scrollbarData && scrollbarData.resize();
        },

        remove: function ($elem) {
            // jQuerify if DOM Element
            if ($elem instanceof Element) {
                $elem = $($elem);
            }

            var scrollbarData = $elem.getNiceScroll();
            scrollbarData && scrollbarData.remove();
        },

        getScrollingHeight: function (elem) {
            var $elem = $(elem);
            var $niceScrollElement = $elem.getNiceScroll()[0];
            if (!$niceScrollElement) {
                return 0;
            }
            return $niceScrollElement.page.maxh;
        },

        isVisible: function ($elem) {
            var scrollbarData = $elem.getNiceScroll();
            if (scrollbarData && scrollbarData.length > 0) {
                var i,
                    len = scrollbarData.length,
                    rail;
                for (i = 0; i < len; i++) {
                    rail = scrollbarData[i].rail;
                    if (typeof rail !== "undefined" && rail.is(":visible")) {
                        return true;
                    }
                }
            }
            return false;
        },

        // custom init
        addEshopHorizontalScrollBar: function (parent, content) {
            // if parent or content is undefined e-shop iframe setting is used
            if (typeof parent === "undefined") {
                parent = self.defaults.eshopParentElemnt;
            }
            if (typeof content === "undefined") {
                content = self.defaults.eshopContentElement;
            }
            var $parent = $(parent),
                $content = $(content);

            if ($parent.length && $content.length) {
                if (
                    typeof $parent.getNiceScroll !== "undefined" &&
                    $parent.getNiceScroll().length > 0
                ) {
                    // scrollbar already added;
                    $parent.getNiceScroll().resize();
                    return;
                }

                var options = $.extend(
                    self.options,
                    {
                        inBoxFloatHorizontal: true,
                    },
                    self.themeOptions[self.themes.big]
                );

                $parent.niceScroll(content, options);

                var scrollbars = $parent.getNiceScroll();
                self.floatHorizontalScrollBar(scrollbars);
            }
        },

        updateEshopHorizontalScrollBar: function (parent) {
            if (typeof parent === "undefined") {
                parent = self.defaults.eshopParentElemnt;
            }
            var $parent = $(parent);
            $parent.getNiceScroll().resize();
        },
    });

    self = ori.virtualScrollBar;

    // Init Virtual Scroll Bar
    $(function () {
        self.init();
    });
})(jQuery);
