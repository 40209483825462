﻿
/*
--------------------------------------------------------------------------------
PBS COOKIE TO URL PARSER FUNCTIONALITY
--------------------------------------------------------------------------------
*/
( function( $ ) {

    'use strict';

    var self,
        SEL_CANONICAL_LINK = ".js-canonical-link";

    ori.addModule("pbsCookieUrl", {
        options: {
            // The cookies are processed in the order they appear in this array,
            // and processing stops at the first found cookie that has stopIfFound == true
            cookies: [
                {
                    cookie: "pbs_code",
                    param: "store",
                    stopIfFound: true,
                },
                {
                    cookie: "ecref_owner",
                    param: "invite",
                    stopIfFound: true,
                },
                {
                    cookie: "pbs_owner",
                    param: "store",
                    stopIfFound: false,
                },
                {
                    cookie: "ppl_code",
                    param: "personallist",
                    stopIfFound: true,
                    stopIfFoundPrev: true,
                    removeIfNotFound: true,
                },
                {
                    cookie: "ecref",
                    param: "invite",
                    stopIfFound: true,
                },
            ]
        },

        foundPrev: false,

        init: function (options) {
            self = this;

            self.options.cookies.some(self.checkCookie);
        },

        checkCookie: function (cookieOptions) {
            var cookieValue = $.cookie(cookieOptions.cookie),
                stop = cookieOptions.stopIfFoundPrev && self.foundPrev;

            if (!cookieValue || !cookieValue.length) {
                if (cookieOptions.removeIfNotFound) {
                    var queryString = window.location.search;
                    queryString = self.removeParam(queryString, cookieOptions.param);
                    self.replaceUrlQuery(queryString);
                }
                self.info(cookieOptions.cookie + " was not found");
                self.foundPrev = false;
                return stop;
            }
            self.checkUrlParam(cookieOptions.param, cookieValue);
            self.foundPrev = true;
            return cookieOptions.stopIfFound || stop;
        },

        checkUrlParam: function(param, value) {
            var queryString = window.location.search,
                pattern = new RegExp("[?&]" + param + "\=" + encodeURIComponent(value) + "([&#]|$)");

            if (pattern.test(queryString)) // already there - nothing to do
                return;
            queryString = self.addOrModifyParam(queryString, param, value);
            self.replaceUrlQuery(queryString);
        },

        /*
         * *************************************
         * TODO when rewriting this piece into TS, move the following 5 functions to Utils.ts
         */
        addParam: function (queryString, param, value) {
            if (queryString.length === 0)
                return "?" + param + "=" + encodeURIComponent(value);
            if (/\?.+/.test(queryString)) {
                return queryString + "&" + param + "=" + encodeURIComponent(value);
            } else { // a really unlikely case - queryString without '?' - in that case, put the param at the beginning; queryString probably starts with a different delimiter
                return "?" + param + "=" + encodeURIComponent(value) + queryString;
            }
        },

        modifyParam: function (queryString, param, value) {
            var pattern = new RegExp("([?&]" + param + ")\=([^&#?;,=]+)");

            return queryString.replace(pattern, "$1=" + encodeURIComponent(value));
       },

        addOrModifyParam: function(queryString, param, value) {
            var pattern = new RegExp("[?&]" + param + "\=");

            if (pattern.test(queryString)) {
                return self.modifyParam(queryString, param, value);
            } else {
                return self.addParam(queryString, param, value);
            }
        },

        removeParam: function (queryString, param) {
            var pattern = new RegExp("([?&]" + param + ")\=([^&#?;,=]+)");

            queryString = queryString.replace(pattern, "");
            if (queryString.length && queryString[0] === "&")
                queryString = "?" + queryString.substr(1);

            return queryString;
        },

        replaceUrlQuery: function (queryString) {
            var title = $('title') ? $('title').eq(0).html() : null,
                url = window.location.origin + window.location.pathname + queryString + window.location.hash;

            window.history.replaceState({}, title, url);
            $(SEL_CANONICAL_LINK).attr("href", url);
        }
        /*
         * /TODO
         * *************************************
         */

    });
    $(function () {
        ori.pbsCookieUrl.init();
    });

})(jQuery);
